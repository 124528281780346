import moment from 'moment'
import { getCurrencyFormat } from '@/i18n/numbers'
import { defaultExportingConfig } from '@/config/highcharts'

function transformPricingAnalysis (data, countryCode = 'es') {
  const ref = 'pricing-analysis'
  const categories = getCategories(data)
  const series = getSeries(data, categories)

  const options = {
    exporting: defaultExportingConfig,
    title: {
      text: ''
    },
    chart: {
      backgroundColor: 'transparent'
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    xAxis: {
      categories: categories,
      labels: {
        formatter: function () {
          const value = this.value
          return moment(value).format('D MMM, YY')
        }
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        formatter: function () {
          if (this.isFirst || this.isLast) {
            return getCurrencyFormat(this.value, countryCode)
          }
        }
      }
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      formatter: function () {
        const date = moment(this.x).format('D MMM, YY')
        let s = `${date}<br/>`
        for (const point of this.points) {
          const value = getCurrencyFormat(point.y, countryCode)
          s += `<span style="color:${point.series.color}">\u2B24</span> ${point.series.name}: <b>${value}</b><br/>`
        }
        return s
      }
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return getCurrencyFormat(this.y, countryCode)
          }
        },
        events: {},
        point: {
          events: {}
        }
      }
    },
    series: series
  }

  const charts = [
    {
      ref,
      options,
      series
    }
  ]

  const brands = getBrands(data)

  return { brands, charts }
}

function getCategories (data) {
  const categories = new Set()
  for (const key in data) {
    const items = data[key].listaPrecios
    for (const d in items) {
      const date = moment(d, 'DD-MM-YYYY').format('YYYY-MM-DD')
      categories.add(date)
    }
  }

  return Array.from(categories).sort()
}

function getSeries (data, categories = null, countryCode = 'es') {
  if (!categories) {
    categories = getCategories(data)
  }

  const series = []
  for (const key in data) {
    const object = data[key].listaPrecios
    if (object && Object.keys(object).length) {
      const serie = []
      for (const category of categories) {
        const date = moment(category).format('DD-MM-YYYY')
        const prices = object[date]

        if (prices && prices.length) {
          serie.push(prices.reduce((a, b) => a + b, 0) / prices.length)
        } else {
          serie.push(null)
        }
      }
      series.push({
        name: key,
        data: serie
      })
    }
  }

  series.sort(compareSeries)

  return series
}

function getBrands (data) {
  return Object.keys(data)
}

function compareSeries (serieA, serieB) {
  if (serieA.name < serieB.name) {
    return -1
  }
  if (serieA.name > serieB.name) {
    return 1
  }
  return 0
}

export default transformPricingAnalysis
