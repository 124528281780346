import { interpolateTurbo } from 'd3-scale-chromatic'
const colorScale = interpolateTurbo

function getColors (length) {
  const colorRangeInfo = {
    start: 0.07,
    end: 1,
    useEndAsStart: false
  }

  const colors = interpolateColors(length, colorScale, colorRangeInfo)

  return colors
}

function interpolateColors (length, colorScale, colorRangeInfo) {
  const { start, end } = colorRangeInfo
  const colorRange = end - start
  const intervalSize = colorRange / length

  const colors = []
  for (let index = 0; index < length; index++) {
    const colorPoint = calculatePoint(index, intervalSize, colorRangeInfo)
    colors.push(colorScale(colorPoint))
  }

  return colors
}

function calculatePoint (index, intervalSize, colorRangeInfo) {
  const { start, end, useEndAsStart } = colorRangeInfo
  return useEndAsStart ? (end - (index * intervalSize)) : (start + (index * intervalSize))
}

export { getColors }
