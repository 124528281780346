import moment from 'moment'
import { getCurrencyFormat } from '@/i18n/numbers'
import { defaultExportingConfig } from '@/config/highcharts'

function transformPriceCompetition (data, countryCode = 'es') {
  const chart = getPriceCompetionChart(data, countryCode)

  const brands = getCompetitionBrands(data)

  return { brands, chart }
}

function transformPositionCompetition (data) {
  const chart = getPositionCompetionChart(data)

  const brands = getCompetitionBrands(data)

  return { brands, chart }
}

function getPriceCompetionChart (data, countryCode) {
  const ref = 'price-competition-product'
  const categories = getCompetitionCategories(data)
  const series = getCompetitionSeries(data, categories, 'pvp')

  const options = {
    exporting: defaultExportingConfig,
    title: {
      text: ''
    },
    chart: {
      backgroundColor: 'transparent'
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    xAxis: {
      categories: categories,
      labels: {
        enabled: false
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        formatter: function () {
          if (this.isFirst || this.isLast) {
            return getCurrencyFormat(this.value, countryCode)
          }
        }
      }
    },
    tooltip: {
      crosshairs: true,
      formatter: function () {
        const date = moment(this.x).format('D MMM, YY')
        const color = this.color
        const point = this.point
        const value = getCurrencyFormat(point.y, countryCode)

        return `${date}<br/><span style="color:${color}">\u2B24</span> <b>${value}</b><br/>`
      }
    },
    series
  }

  return {
    ref,
    options,
    series
  }
}

function getPositionCompetionChart (data) {
  const ref = 'position-competition-product'
  const categories = getCompetitionCategories(data)
  const series = getCompetitionSeries(data, categories, 'position')

  const options = {
    exporting: defaultExportingConfig,
    title: {
      text: ''
    },
    chart: {
      backgroundColor: 'transparent'
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    xAxis: {
      categories: categories,
      labels: {
        enabled: false
      }
    },
    yAxis: {
      allowDecimals: false,
      min: 1,
      tickPositioner: function () {
        const positions = this.tickPositions
        positions[0] = 1

        if (positions.length >= 2 && positions[positions.length - 2] === this.dataMax) {
          positions.pop()
        }

        return positions
      },
      title: {
        text: ''
      },
      labels: {
        formatter: function () {
          if (this.isFirst || this.isLast) {
            return this.value
          }
        }
      },
      reversed: true
    },
    tooltip: {
      crosshairs: true,
      formatter: function () {
        const date = moment(this.x).format('D MMM, YY')
        const color = this.color
        const point = this.point
        const value = point.y

        return `${date}<br/><span style="color:${color}">\u2B24</span> <b>${value}</b><br/>`
      }
    },
    series
  }

  return {
    ref,
    options,
    series
  }
}

function getCompetitionCategories (data) {
  const categories = new Set()
  for (const key in data) {
    const items = data[key]
    for (const item of items) {
      const date = moment(item.fecha, 'DD-MM-YYYY').format('YYYY-MM-DD')
      categories.add(date)
    }
  }

  return Array.from(categories).sort()
}

function getCompetitionSeries (data, categories = null, field = 'pvp') {
  if (!categories) {
    categories = getCompetitionCategories(data)
  }

  const series = []
  for (const key in data) {
    const items = data[key]

    if (items && items.length) {
      const serie = []
      for (const category of categories) {
        const date = moment(category).format('DD-MM-YYYY')
        const item = items.find(i => i.fecha === date)

        if (item && item[field]) {
          serie.push(item[field])
        } else {
          serie.push(null)
        }
      }
      series.push({
        name: items[0].name,
        data: serie
      })
    }
  }

  series.sort(compareSeries)

  return series
}

function getCompetitionBrands (data) {
  const names = new Set()

  for (const key in data) {
    const items = data[key]
    if (items && items.length) {
      names.add(items[0].name)
    }
  }

  return Array.from(names).sort()
}

function compareSeries (serieA, serieB) {
  if (serieA.name < serieB.name) {
    return -1
  }
  if (serieA.name > serieB.name) {
    return 1
  }
  return 0
}

export { transformPriceCompetition, transformPositionCompetition }
