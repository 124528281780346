import moment from 'moment'
import { getPercentFormat } from '@/i18n/numbers'
import { i18n } from '@/i18n'
import { defaultExportingConfig } from '@/config/highcharts'

const othersLabel = i18n.t('app.others')

function transformTop20 (data, limit = null) {
  const charts = []
  const totalCategories = []
  data.forEach(item => {
    totalCategories.push(...Object.keys(item.brandShareGlobal))
    const dataList = item.listaBrandShare
    const categories = getCategories(dataList, limit)
    const ref = item.sitio
    const brandsSortedByRelevance = getBrandsSortedByRelevance(dataList)

    let series = getSeries(dataList, categories, limit)
    series = getSeriesSortedByRelevance(series, brandsSortedByRelevance)

    const options = {
      exporting: defaultExportingConfig,
      title: {
        text: ''
      },
      chart: {
        type: 'column',
        backgroundColor: 'transparent'
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: categories,
        labels: {
          formatter: function () {
            const value = this.value
            return moment(value, 'YYYY-MM-DD').format('D MMM, YY')
          }
        }
      },
      yAxis: {
        title: {
          text: ''
        },
        max: 100,
        labels: {
          enabled: false
        },
        reversedStacks: false
      },
      legend: {
        enabled: false
      },
      tooltip: {
        formatter: function () {
          const series = this.series
          const date = moment(this.x, 'YYYY-MM-DD').format('D MMM, YY')
          const percentage = getPercentFormat(this.y)
          return `${date}<br/><span style="color:${series.color}">\u2B24</span> ${series.name}: <b>${percentage}</b><br/>`
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          groupPadding: 0
        },
        series: {
          events: {}
        }
      },
      series: series
    }

    charts.push({
      site: ref,
      ref,
      options,
      series
    })
  })

  const brands = getBrands(charts)

  totalCategories.push(othersLabel)
  return { brands, totalCategories: Array.from(new Set(totalCategories)), charts }
}

function getCategories (data, limit = null) {
  const categories = Object.keys(data)

  return categories.map(category => moment(category, 'DD-MM-YYYY').format('YYYY-MM-DD'))
}

function getSeries (data, categories, limit = null) {
  let series = {}
  const maxSerieLength = categories.length
  for (const date in data) {
    const d = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')
    const position = categories.indexOf(d)
    const dateData = data[date]
    const total = Object.values(dateData).reduce((a, b) => a + b, 0)
    const avalaibleData = getBestData(dateData, limit)

    for (const brand in avalaibleData) {
      const value = (avalaibleData[brand] * 100) / total
      if (series[brand]) {
        series[brand][position] = value
      } else {
        const values = []
        values[position] = value
        series[brand] = values
      }
    }
  }

  series = Object.keys(series).map(key => {
    const data = serielizeArray(series[key], maxSerieLength)
    return {
      name: key,
      data: data
    }
  })

  series = sortSeries(series)
  return series
}

function getBrands (charts) {
  const names = new Set()

  for (const chart of charts) {
    for (const serie of chart.series) {
      names.add(serie.name)
    }
  }

  const brands = Array.from(names).sort()

  return brands
}

function sortSeries (series) {
  series.sort(compareSeries)
  const other = series.find(item => item.name === othersLabel)

  if (other) {
    series = series.filter(item => item.name !== othersLabel)
    series.push(other)
  }
  return series
}

function compareSeries (serieA, serieB) {
  if (serieA.name.toLowerCase() < serieB.name.toLowerCase()) {
    return -1
  }
  if (serieA.name.toLowerCase() > serieB.name.toLowerCase()) {
    return 1
  }
  return 0
}

function serielizeArray (array, length = null) {
  if (length) {
    array.length = length
  }

  for (let index = 0; index < array.length; index++) {
    const element = array[index]
    if (!element && element !== 0) {
      array[index] = null
    }
  }

  return array
}

function getBestData (data, limit = null) {
  if (!limit || limit >= Object.keys(data).length) {
    return data
  }
  const serializeData = Object.keys(data).map(key => {
    return {
      name: key,
      value: data[key]
    }
  })

  serializeData.sort((a, b) => {
    if (a.value < b.value) {
      return 1
    }
    if (a.value > b.value) {
      return -1
    }
    return 0
  })

  const bestData = serializeData.slice(0, limit)
  const worstData = serializeData.slice(limit)
  const worstValue = Object.values(worstData).map(item => item.value).reduce((a, b) => a + b, 0)
  const results = {}
  for (const item of bestData) {
    results[item.name] = item.value
  }

  return {
    ...results,
    [othersLabel]: worstValue
  }
}

function getBrandsSortedByRelevance (data) {
  const brands = {}
  for (const date in data) {
    const object = data[date]
    for (const brand in object) {
      if (brand in brands) {
        brands[brand] += object[brand]
      } else {
        brands[brand] = object[brand]
      }
    }
  }

  const sorteable = []
  for (const brand in brands) {
    sorteable.push([brand, brands[brand]])
  }

  sorteable.sort((a, b) => b[1] - a[1])
  return sorteable.map(item => item[0]).concat([othersLabel])
}

function getSeriesSortedByRelevance (series, brandsSortedByRelevance) {
  const orderSeries = []
  for (const brand of brandsSortedByRelevance) {
    const serie = series.find(s => s.name === brand)
    if (serie) {
      orderSeries.push(serie)
    }
  }

  return orderSeries
}

export default transformTop20
