import moment from 'moment'
import { getPercentFormat } from '@/i18n/numbers'
import { i18n } from '@/i18n'
import { defaultExportingConfig } from '@/config/highcharts'

const othersLabel = i18n.t('app.others')

function transformBrandShare (data, limit = null) {
  const brandsSortedByRelevance = getBrandsSortedByRelevance(data)
  const averageChart = getAverageChart(data, limit)
  const generalChart = getGeneralChart(data, limit)

  let charts = [averageChart, generalChart]
  charts = getChartsSortedByRelevance(charts, brandsSortedByRelevance)

  let categories = new Set()
  for (const chart of charts) {
    chart.series.forEach(item => {
      categories.add(item.name)
    })
  }
  categories = Array.from(categories)

  let totalCategories = []
  for (const item of data) {
    const object = item.brandShareGlobal
    totalCategories.push(...Object.keys(object), othersLabel)
  }
  totalCategories = Array.from(new Set(totalCategories))

  return {
    categories: categories,
    totalCategories: totalCategories,
    charts
  }
}

function getGeneralChart (data, limit) {
  const categories = getCategories(data)
  const ref = 'brand-share'
  const series = getSeries(data, categories, limit)

  const options = {
    exporting: defaultExportingConfig,
    title: {
      text: ''
    },
    chart: {
      type: 'column',
      backgroundColor: 'transparent'
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: categories,
      labels: {
        formatter: function () {
          const value = this.value
          return moment(value).format('D MMM, YY')
        }
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      max: 100,
      labels: {
        enabled: false
      },
      reversedStacks: false
    },
    legend: {
      enabled: false
    },
    tooltip: {
      formatter: function () {
        const series = this.series
        const date = moment(this.x).format('D MMM, YY')
        const percentage = getPercentFormat(this.y)
        return `${date}<br/><span style="color:${series.color}">\u2B24</span> ${series.name}: <b>${percentage}</b><br/>`
      }
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        groupPadding: 0
      },
      series: {
        events: {}
      }
    },
    series: series
  }

  return {
    ref,
    options,
    series
  }
}

function getAverageChart (data, limit = null) {
  const categories = getAverageCategories(data)
  const ref = 'average-brand-share'
  const series = getAverageSeries(data, categories, limit)

  const options = {
    exporting: defaultExportingConfig,
    title: {
      text: ''
    },
    boost: {
      enabled: false
    },
    chart: {
      type: 'column',
      backgroundColor: 'transparent'
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: categories,
      labels: {
        formatter: function () {
          return i18n.t('app.average')
        }
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      max: 100,
      labels: {
        enabled: false
      },
      reversedStacks: false
    },
    legend: {
      enabled: false
    },
    tooltip: {
      outside: true,
      formatter: function () {
        const series = this.series
        const x = this.x
        const percentage = getPercentFormat(this.y)
        return `${x}<br><span style="color:${series.color}">\u2B24</span> ${series.name}: <b>${percentage}</b><br/>`
      }
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        groupPadding: 0
      },
      series: {
        events: {}
      }
    },
    series: series
  }

  return {
    ref,
    options,
    series
  }
}

function getCategories (data) {
  const categories = new Set()
  for (const item of data) {
    const object = item.listaBrandShare
    for (const key in object) {
      const date = moment(key, 'DD-MM-YYYY').format('YYYY-MM-DD')
      categories.add(date)
    }
  }

  return Array.from(categories).sort()
}

function getAverageCategories (data) {
  return data.map(item => item.sitio)
}

function getSeries (data, categories, limit = null) {
  let series = {}
  const maxSerieLength = categories.length
  for (const item of data) {
    const object = item.listaBrandShare

    for (const date in object) {
      const d = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')
      const position = categories.indexOf(d)
      const dateData = object[date]
      const total = Object.values(dateData).reduce((a, b) => a + b, 0)
      const avalaibleData = getBestData(dateData, limit)

      for (const brand in avalaibleData) {
        const value = (avalaibleData[brand] * 100) / total
        if (series[brand]) {
          series[brand][position] = value
        } else {
          const values = []
          values[position] = value
          series[brand] = values
        }
      }
    }
  }

  series = Object.keys(series).map(key => {
    const data = serielizeArray(series[key], maxSerieLength)
    return {
      name: key,
      data: data
    }
  })

  series = sortSeries(series)

  return series
}

function getAverageSeries (data, categories, limit = null) {
  let series = {}
  const maxSerieLength = categories.length
  for (const item of data) {
    const object = item.brandShareGlobal
    const position = categories.indexOf(item.sitio)
    const total = Object.values(object).reduce((a, b) => a + b, 0)
    const avalaibleData = getBestData(object, limit)

    for (const brand in avalaibleData) {
      const value = (avalaibleData[brand] * 100) / total
      if (series[brand]) {
        series[brand][position] = value
      } else {
        const values = []
        values[position] = value
        series[brand] = values
      }
    }
  }
  series = Object.keys(series).map(key => {
    const data = serielizeArray(series[key], maxSerieLength)
    return {
      name: key,
      data: data
    }
  })

  series = sortSeries(series)

  return series
}

function sortSeries (series) {
  series.sort(compareSeries)
  const other = series.find(item => item.name === othersLabel)

  if (other) {
    series = series.filter(item => item.name !== othersLabel)
    series.push(other)
  }
  return series
}

function compareSeries (serieA, serieB) {
  if (serieA.name < serieB.name) {
    return -1
  }
  if (serieA.name > serieB.name) {
    return 1
  }
  return 0
}

function serielizeArray (array, length = null) {
  if (length) {
    array.length = length
  }

  for (let index = 0; index < array.length; index++) {
    const element = array[index]
    if (!element && element !== 0) {
      array[index] = null
    }
  }

  return array
}

function getBestData (data, limit = null) {
  if (!limit || limit >= Object.keys(data).length) {
    return data
  }
  const serializeData = Object.keys(data).map(key => {
    return {
      name: key,
      value: data[key]
    }
  })

  serializeData.sort((a, b) => {
    if (a.value < b.value) {
      return 1
    }
    if (a.value > b.value) {
      return -1
    }
    return 0
  })

  const bestData = serializeData.slice(0, limit)
  const worstData = serializeData.slice(limit)
  const worstValue = Object.values(worstData).map(item => item.value).reduce((a, b) => a + b, 0)
  const results = {}
  for (const item of bestData) {
    results[item.name] = item.value
  }

  return {
    ...results,
    [othersLabel]: worstValue
  }
}

function getBrandsSortedByRelevance (data) {
  if (data && data.length) {
    const item = data[0]
    const object = item.brandShareGlobal

    const sorteable = []
    for (const brand in object) {
      sorteable.push([brand, object[brand]])
    }

    sorteable.sort((a, b) => b[1] - a[1])
    return sorteable.map(item => item[0]).concat([othersLabel])
  }
}

function getChartsSortedByRelevance (charts, brandsSortedByRelevance) {
  for (const chart of charts) {
    const series = []
    for (const brand of brandsSortedByRelevance) {
      const serie = chart.series.find(s => s.name === brand)
      if (serie) {
        series.push(serie)
      }
    }
    chart.series = series
    chart.options.series = series
  }

  return charts
}

export default transformBrandShare
