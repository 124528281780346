import moment from 'moment'
import { getCurrencyFormat } from '@/i18n/numbers'
import { defaultExportingConfig } from '@/config/highcharts'

function transformCompetition (data, countryCode = 'es') {
  const charts = []

  charts.push(getPriceCompetionChart(data, countryCode))
  charts.push(getPositionCompetionChart(data))

  const brands = getCompetitionBrands(data)

  return { brands, charts }
}

function getPriceCompetionChart (data, countryCode) {
  const ref = 'price-competition'
  const categories = getCompetitionCategories(data)
  const series = getCompetitionSeries(data, categories, 'pvp')

  const options = {
    exporting: defaultExportingConfig,
    title: {
      text: ''
    },
    chart: {
      backgroundColor: 'transparent'
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    xAxis: {
      categories: categories,
      labels: {
        enabled: false
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        formatter: function () {
          if (this.isFirst || this.isLast) {
            return getCurrencyFormat(this.value, countryCode)
          }
        }
      }
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      formatter: function () {
        const date = moment(this.x).format('D MMM, YY')
        let s = `${date}<br/>`
        for (const point of this.points) {
          const value = getCurrencyFormat(point.y, countryCode)
          s += `<span style="color:${point.series.color}">\u2B24</span> ${point.series.name}: <b>${value}</b><br/>`
        }
        return s
      }
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return getCurrencyFormat(this.y, countryCode)
          }
        },
        events: {},
        point: {
          events: {}
        }
      }
    },
    series: series
  }

  return {
    ref,
    options,
    series
  }
}

function getPositionCompetionChart (data) {
  const ref = 'position-competition'
  const categories = getCompetitionCategories(data)
  const series = getCompetitionSeries(data, categories, 'position')

  const options = {
    exporting: defaultExportingConfig,
    title: {
      text: ''
    },
    chart: {
      backgroundColor: 'transparent'
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    xAxis: {
      categories: categories,
      labels: {
        formatter: function () {
          const value = this.value
          return moment(value).format('D MMM, YY')
        }
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      reversed: true,
      min: 1,
      tickPositioner: function () {
        const positions = this.tickPositions
        if (positions.length) {
          positions[0] = 1
        }
        return positions
      },
      labels: {
        formatter: function () {
          if (this.isFirst || this.isLast) {
            return this.value
          }
        }
      }
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      formatter: function () {
        const date = moment(this.x).format('D MMM, YY')
        let s = `${date}<br/>`
        for (const point of this.points) {
          const value = point.y
          s += `<span style="color:${point.series.color}">\u2B24</span> ${point.series.name}: <b>${value}</b><br/>`
        }
        return s
      }
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true
        },
        point: {
          events: {}
        }
      }
    },
    series: series
  }

  return {
    ref,
    options,
    series
  }
}

function getCompetitionCategories (data) {
  const categories = new Set()
  for (const key in data) {
    const items = data[key]
    for (const item of items) {
      const date = moment(item.fecha, 'DD-MM-YYYY').format('YYYY-MM-DD')
      categories.add(date)
    }
  }

  return Array.from(categories).sort()
}

function getCompetitionSeries (data, categories = null, field = 'pvp') {
  if (!categories) {
    categories = getCompetitionCategories(data)
  }

  const series = []
  for (const key in data) {
    const items = data[key]

    if (items && items.length) {
      const serie = []
      for (const category of categories) {
        const date = moment(category).format('DD-MM-YYYY')
        const item = items.find(i => i.fecha === date)

        if (item && item[field]) {
          serie.push(item[field])
        } else {
          serie.push(null)
        }
      }
      series.push({
        name: items[0].name,
        data: serie
      })
    }
  }

  series.sort(compareSeries)

  return series
}

function getCompetitionBrands (data) {
  const names = new Set()

  for (const key in data) {
    const items = data[key]
    if (items && items.length) {
      names.add(items[0].name)
    }
  }

  return Array.from(names).sort()
}

function compareSeries (serieA, serieB) {
  if (serieA.name < serieB.name) {
    return -1
  }
  if (serieA.name > serieB.name) {
    return 1
  }
  return 0
}

export default transformCompetition
