<template>
  <div
    @mouseover="onMouseover(category)"
    @mouseleave="onMouseleave(category)"
    :style="setBorderOnHover"
    class="flex border-solid border-l-8 border-2 border-transparent items-center px-3 py-2 bg-white transition-shadow duration-150 shadow hover:shadow-lg rounded-lg"
    :class="[{ 'opacity-50': disabled }, customClass]"
  >
    <div class="flex items-center w-full">
      <div class="overflow-hidden w-full">
        <header class="leading-tight" v-tooltip="tooltipContent">
          <h3 class="font-semibold truncate text-sm">{{ category }}</h3>
          <span v-if="subtitle" class="text-sm text-secondary truncate">{{ subtitle }}</span>
        </header>
        <div v-if="text" class="text-xs">
          <p>{{ text }}</p>
        </div>
      </div>
      <div class="flex-shrink-0 ml-2 flex items-center space-x-2">
        <base-toggle-button @click="highlight(category)" icon="highlighter" :status="highlightStatus" />
        <base-toggle-button @click="toggle(category)" icon="eye" :status="enabled" :inverse="true" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'legend-label',
  props: {
    category: {
      type: String,
      required: true
    },
    subtitle: {
      type: String
    },
    text: {
      type: String
    },
    color: {
      type: String,
      required: true
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      hover: false,
      enabled: true,
      highlightStatus: false
    }
  },
  computed: {
    setBorderOnHover () {
      return this.hover || this.highlightStatus ? 'border-color:' + this.color : 'border-left-color:' + this.color
    },
    disabled: function () {
      return !this.enabled
    },
    tooltipContent: function () {
      if (this.category.length < 12) {
        return { show: false }
      } else {
        return this.category
      }
    }
  },
  methods: {
    toggle: function (name) {
      this.enabled = !this.enabled
      this.$emit('toggle', name)
    },
    highlight: function (name) {
      this.highlightStatus = !this.highlightStatus
      this.$emit('highlight', name)
    },
    onMouseover: function (name) {
      this.hover = true
      this.$emit('mouseover', name)
    },
    onMouseleave: function (name) {
      this.hover = false
      this.$emit('mouseleave', name)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
