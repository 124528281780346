import moment from 'moment'
import { getPercentFormat } from '@/i18n/numbers'
import { i18n } from '@/i18n'
import { defaultExportingConfig } from '@/config/highcharts'

const undefinedLabel = '-'

function transformFeatures (data) {
  const brandsSortedByRelevance = getBrandsSortedByRelevance(data)
  const averageChart = getAverageChart(data)
  const generalChart = getGeneralChart(data)
  const treemapChart = getTreemapChart(data)

  const charts = [averageChart, generalChart, treemapChart]
  // charts = getChartsSortedByRelevance(charts, brandsSortedByRelevance)

  return { brands: brandsSortedByRelevance, charts }
}

function getGeneralChart (data) {
  const categories = getCategories(data)
  const ref = 'features'
  const series = getSeries(data, categories)

  const options = {
    exporting: defaultExportingConfig,
    title: {
      text: ''
    },
    chart: {
      type: 'column',
      backgroundColor: 'transparent'
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: categories,
      labels: {
        formatter: function () {
          const value = this.value
          return moment(value).format('D MMM, YY')
        }
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      max: 100,
      labels: {
        enabled: false
      },
      reversedStacks: false
    },
    legend: {
      enabled: false
    },
    tooltip: {
      formatter: function () {
        const series = this.series
        const date = moment(this.x).format('D MMM, YY')
        const percentage = getPercentFormat(this.y)
        return `${date}<br/><span style="color:${series.color}">\u2B24</span> ${series.name}: <b>${percentage}</b><br/>`
      }
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        groupPadding: 0
      },
      series: {
        events: {}
      }
    },
    series: series
  }

  return {
    ref,
    options,
    series
  }
}

function getAverageChart (data) {
  const categories = getAverageCategories(data)
  const ref = 'average-features'
  const series = getAverageSeries(data, categories)

  const options = {
    exporting: defaultExportingConfig,
    title: {
      text: ''
    },
    boost: {
      enabled: false
    },
    chart: {
      type: 'column',
      backgroundColor: 'transparent'
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: categories,
      labels: {
        formatter: function () {
          return i18n.t('app.average')
        }
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      max: 100,
      labels: {
        enabled: false
      },
      reversedStacks: false
    },
    legend: {
      enabled: false
    },
    tooltip: {
      outside: true,
      formatter: function () {
        const series = this.series
        const x = this.x
        const percentage = getPercentFormat(this.y)
        return `${x}<br><span style="color:${series.color}">\u2B24</span> ${series.name}: <b>${percentage}</b><br/>`
      }
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        groupPadding: 0
      },
      series: {
        events: {}
      }
    },
    series: series
  }

  return {
    ref,
    options,
    series
  }
}

function getTreemapChart (data) {
  const ref = 'treemap-features'
  const series = getTreemapSeries(data)

  const options = {
    exporting: defaultExportingConfig,
    title: {
      text: ''
    },
    chart: {
      backgroundColor: 'transparent',
      events: {}
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    tooltip: {
      formatter: function () {
        const key = this.key
        const point = this.point
        const value = point.value
        const color = this.color

        let total = 0
        for (var i = 0; i < this.series.data.length; i++) {
          if (this.series.data[i].node.children.length === 0) {
            total += this.series.data[i].node.val
          }
        }

        const parent = point.parent
        const percent = getPercentFormat((value * 100) / total)
        let s = '<b>' + key + ': ' + percent + '</b>'
        if (parent) {
          s = `<span style="color:${color}">\u2B24</span> ` + parent + '<br/>' + s
        } else {
          s = `<span style="color:${color}">\u2B24</span>` + s
        }

        return s
      }
    },
    plotOptions: {
      series: {
        events: {},
        point: {
          events: {}
        }
      }
    },
    series
  }

  return {
    ref,
    options,
    series
  }
}

function getCategories (data) {
  const categories = new Set()
  for (const item of data) {
    const object = item.listaBrandShare
    for (const key in object) {
      const date = moment(key, 'DD-MM-YYYY').format('YYYY-MM-DD')
      categories.add(date)
    }
  }

  return Array.from(categories).sort()
}

function getAverageCategories (data) {
  return data.map(item => item.sitio)
}

function getSeries (data, categories = null) {
  if (!categories) {
    categories = getCategories(data)
  }

  let series = {}
  const maxSerieLength = categories.length
  for (const item of data) {
    const object = item.listaBrandShare

    for (const date in object) {
      const d = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')
      const position = categories.indexOf(d)
      const dateData = object[date]
      const total = Object.values(dateData).reduce((a, b) => a + b, 0)

      for (const brand in dateData) {
        const value = (dateData[brand] * 100) / total
        if (series[brand]) {
          series[brand][position] = value
        } else {
          const values = []
          values[position] = value
          series[brand] = values
        }
      }
    }
  }

  series = Object.keys(series).map(key => {
    const data = serielizeArray(series[key], maxSerieLength)
    return {
      name: key || undefinedLabel,
      data: data
    }
  })

  series.sort(compareSeries)

  return series
}

function getAverageSeries (data, categories = null) {
  if (!categories) {
    categories = getAverageCategories(data)
  }
  let series = {}
  const maxSerieLength = categories.length
  for (const item of data) {
    const object = item.brandShareGlobal
    const position = categories.indexOf(item.sitio)
    const total = Object.values(object).reduce((a, b) => a + b, 0)

    for (const brand in object) {
      const value = (object[brand] * 100) / total
      if (series[brand]) {
        series[brand][position] = value
      } else {
        const values = []
        values[position] = value
        series[brand] = values
      }
    }
  }

  series = Object.keys(series).map(key => {
    const data = serielizeArray(series[key], maxSerieLength)
    return {
      name: key || undefinedLabel,
      data: data
    }
  })

  series.sort(compareSeries)

  return series
}

function getTreemapSeries (data) {
  const series = []
  for (const item of data) {
    const serieData = []
    const object = item.brandShareGlobalMarcas
    for (const feature in object) {
      serieData.push({
        id: feature || undefinedLabel,
        name: feature || undefinedLabel
      })

      const serieObject = item.brandShareGlobalMarcas[feature]
      for (const key of Object.keys(serieObject)) {
        serieData.push({
          parent: feature || undefinedLabel,
          name: key,
          value: serieObject[key]
        })
      }
    }
    series.push({
      type: 'treemap',
      alternateStartingDirection: true,
      allowTraversingTree: true,
      levelIsConstant: false,
      data: serieData,
      levels: [{
        level: 1,
        layoutAlgorithm: 'sliceAndDice',
        dataLabels: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            textOutline: '1px contrast'
          }
        }
      }],
      dataLabels: {
        enabled: true,
        style: {
          // textOutline: undefined
        },
        formatter: function () {
          const key = this.key
          const point = this.point
          const value = point.value

          if (value) {
            let total = 0
            for (var i = 0; i < this.series.data.length; i++) {
              if (this.series.data[i].node.children.length === 0) {
                total += this.series.data[i].node.val
              }
            }

            const percent = getPercentFormat((value * 100) / total)

            return key + ': ' + percent
          }

          return key
        }
      }
    })
  }

  return series
}

function compareSeries (serieA, serieB) {
  if (serieA.name < serieB.name) {
    return -1
  }
  if (serieA.name > serieB.name) {
    return 1
  }
  return 0
}

function serielizeArray (array, length = null) {
  if (length) {
    array.length = length
  }

  for (let index = 0; index < array.length; index++) {
    const element = array[index]
    if (!element && element !== 0) {
      array[index] = null
    }
  }

  return array
}

function getBrandsSortedByRelevance (data) {
  if (data && data.length) {
    const item = data[0]
    const object = item.brandShareGlobal

    const sorteable = []
    for (const brand in object) {
      sorteable.push([brand || undefinedLabel, object[brand]])
    }

    sorteable.sort((a, b) => b[1] - a[1])
    return sorteable.map(item => item[0])
  }
}

/* function getChartsSortedByRelevance (charts, brandsSortedByRelevance) {
  for (const chart of charts) {
    const series = []
    for (const brand of brandsSortedByRelevance) {
      const serie = chart.series.find(s => s.name === brand)
      if (serie) {
        series.push(serie)
      }
    }
    chart.series = series
    chart.options.series = series
  }

  return charts
} */

export default transformFeatures
