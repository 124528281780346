const defaultExportingConfig = {
  menuItemDefinitions: {
    downloadPNG: {
      onclick: function () {
        this.update({
          legend: {
            enabled: true
          }
        })
        this.exportChart({
          type: 'image/png'
        })
        this.update({
          legend: {
            enabled: false
          }
        })
      },
      text: 'Download PNG'
    },
    downloadJPG: {
      onclick: function () {
        this.update({
          legend: {
            enabled: true
          }
        })
        this.exportChart({
          type: 'image/jpeg'
        })
        this.update({
          legend: {
            enabled: false
          }
        })
      },
      text: 'Download JPG'
    },
    downloadPDF: {
      onclick: function () {
        this.update({
          legend: {
            enabled: true
          }
        })
        this.exportChart({
          type: 'application/pdf'
        })
        this.update({
          legend: {
            enabled: false
          }
        })
      },
      text: 'Download PDF'
    },
    downloadSVG: {
      onclick: function () {
        this.update({
          legend: {
            enabled: true
          }
        })
        this.exportChart()
        this.update({
          legend: {
            enabled: false
          }
        })
      },
      text: 'Download SVG'
    }
  },
  buttons: {
    contextButton: {
      menuItems: ['viewFullscreen', 'separator', 'downloadPNG', 'downloadJPG', 'downloadPDF', 'downloadSVG']
    }
  }
}

export {
  defaultExportingConfig
}
